import React from "react"
import Footer from "../../layout/Footer"
import { background } from "../../shared/styles"
import Section from "../../layout/Section"
import SectionHeader from "../../layout/SectionHeader"

export default function HelpScreen() {
  return (
    <>
      <Section backgroundColor={background.bg}>
        <SectionHeader>How can we help you?</SectionHeader>
      </Section>
      <Footer />
    </>
  )
}
